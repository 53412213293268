import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GeneratePasswordLink } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';
import { setAccountEmail } from '../features/registration/registrationJourneySlice';
import { resetPasswordGeneral } from '../firebase';
import useTitle from '../hooks/useTitle';

const PasswordRequestLink = () => {
  const [captureInput, setCaptureInput] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'SMEReg.PasswordRequestLink.s6',
    'Request a link to reset your password - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  // Error message
  const invalidEmailErrorMsg = copyProvider.getCopy(
    'SMEReg.PasswordRequestLink.s5',
    'Enter a valid email address'
  );

  // Handle button function
  const handleButtonClick = () => {
    resetPasswordGeneral({ email: captureInput })
      .then(() => {
        dispatch(setAccountEmail(captureInput));
        history.push('/confirm-link-sent');
      })
      .catch(() => {
        // TODO: Show an error
      });
  };

  // Handle input field
  const handleInput = (event) => {
    setCaptureInput(event.target.value);
  };
  const pageModel = (
    <GeneratePasswordLink
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      heading={copyProvider.getCopy(
        'SMEReg.PasswordRequestLink.s1',
        'Request a link to reset your password'
      )}
      body={copyProvider.getCopy(
        'SMEReg.PasswordRequestLink.s2',
        'Enter your email address'
      )}
      email={{
        hint: copyProvider.getCopy(
          'SMEReg.PasswordRequestLink.s3',
          'We will send a link to this email to reset your password'
        ),
        onChange: handleInput,
        value: captureInput,
        errorMessage: invalidEmailErrorMsg,
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.PasswordRequestLink.s4',
          'Send a link'
        ),
        onClick: handleButtonClick,
      }}
    />
  );
  return pageModel;
};

export default PasswordRequestLink;
